export default function getRobotsValue(
  noIndex: boolean | undefined,
  noFollow: boolean | undefined
): string {
  if (noIndex && noFollow) {
    return 'noindex, nofollow';
  } else if (noIndex) {
    return 'noindex, follow';
  } else if (noFollow) {
    return 'index, nofollow';
  } else {
    return 'index, follow';
  }
}
